import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row } from 'antd'

import HubspotForm from 'react-hubspot-form'

import UI_Integration from "../images/img_Tutorials_UI_Integration.jpg"
import Lorem_Ipsum from "../images/img_Tutorials_Lorem_Ipsum.jpg"

const BecomePartner = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">
        <div className="flex-item not_found">
          <h1>Become a Partner</h1>
          <p>Registering gives you access to all the information you will need to understand, install, and operate the Strands Finance Suite products.</p>
          <h2 className="small">1. Sign up</h2>
          <p>Fill out the form on the right to create your account.</p>
          <h2 className="small">2. Verify account</h2>
          <p>Click the link we send to your email to confirm registration.</p>
          <h2 className="small">3. Begin building</h2>
          <p>Log in to explore our portal and begin integrating our APIs with a few lines of code.<br></br>
            You can choose from over 30 APIs that meet your needs.</p>
        </div>
        <div className="flex-item not_found">
          <HubspotForm
            portalId='471759'
            formId='69bc32c7-029c-4f12-90bc-56452671932e'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </div>
      </div>
    </Row>
  </SiteLayout>
)

export default BecomePartner
